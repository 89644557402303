import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import leaf from "../../Assets/Projects/leaf.png";
// import emotion from "../../Assets/Projects/emotion.png";
// import editor from "../../Assets/Projects/codeEditor.png";
// import chatify from "../../Assets/Projects/chatify.png";
// import suicide from "../../Assets/Projects/suicide.png";
// import bitsOfCode from "../../Assets/Projects/blog.png";

// import Formation from "../../Assets/Basile/Formation.png";

import Formation1 from "../../Assets/Basile/1.jpg";
import Formation2 from "../../Assets/Basile/2.jpg";
import Formation3 from "../../Assets/Basile/3.jpg";

import Vue1 from "../../Assets/Basile/Vue1.jpeg";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          NOS <strong className="purple">Formations </strong>
        </h1>
        <p style={{ color: "black" }}>
        Les formations sont assurés par des experts du domaine
        </p>

        <Row style={{  justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card"
           data-aos="fade-right"
           data-aos-duration="600"
           >
            <ProjectCard
              imgPath={Formation1} 
              isBlog={false}
              prix="35000"
              title="Initiation à l’infographie (Illustrator, Photoshop et In design)"
              description="Personal Chat Room or Workspace to share resources and hangout with friends build with react.js, Material-UI, and Firebase. Have features which allows user for realtime messaging, image sharing as well as supports reactions on messages."
              demoLink="https://google.com/"
            />
          </Col>

          <Col md={4} className="project-card"
           data-aos="flip-down"
           data-aos-duration="600"
           >
            <ProjectCard 
              imgPath={Formation2}
              isBlog={false}
              prix="50000"
              title="Perfectionnement en infographie (Illustrator & Photoshop)"
              description="Personal Chat Room or Workspace to share resources and hangout with friends build with react.js, Material-UI, and Firebase. Have features which allows user for realtime messaging, image sharing as well as supports reactions on messages."
              demoLink="https://google.com/"
            />
          </Col>

          <Col md={4} className="project-card"
           data-aos="fade-left"
           data-aos-duration="600"
           >
            <ProjectCard
              imgPath={Formation3}
              isBlog={false}
              prix="50000"
              title="Spécialisation (un logiciel au choix)"
              description="Personal Chat Room or Workspace to share resources and hangout with friends build with react.js, Material-UI, and Firebase. Have features which allows user for realtime messaging, image sharing as well as supports reactions on messages."
              demoLink="https://google.com/"          
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
