import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Conception de bannière web",
          "Conception de spot radio et télé",
          "Conception de capsule vidéo de 2 mn",
          "Diffusion de spot radio et télé",
          "Affichage urbain",
          "Participation aux émissions télé/radio",
          "Publication sponsorisé sur les réseaux sociaux",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;


