import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineFacebook,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 className="titre1">
              NOTRE <span className="purple"> PUBLIC </span> CIBLE
            </h1>


            <p className="home-about-body">
             <b className="purple">Les jeunes lycéens et étudiants </b>
             désirant embrasser une carrière professionnelle dans le domaine de la communication et marketing
              <br /> <br />
              <b className="purple">Les jeunes entrepreneurs </b>
              du marketing digital et autres domaines d’activités ;
              <br /> <br />
              <b className="purple">Les gérants </b>
              de secrétariat publicsou privés ;
              <br /> <br />
              <b className="purple">Les professionnels </b>
               voulant renforcer leurs compétences en création de contenus numériques ;
              <br /> <br />
              <b className="purple">Toutes autres personnes physiques ou morales </b>
               trouvant un intérêt particulier à cette formation           
              
    
            </p>
          </Col>

        </Row>

      </Container>
    </Container>
  );
}
export default Home2;
