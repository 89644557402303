import React ,  { useState } from 'react';
import { Container, Row, Col, Stack } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import {
  Button,
  Modal,
  Box,
  Center,
  FormControl,
  AlertDialog,
  Input,
  Select,  CheckIcon, WarningOutlineIcon,
  NativeBaseProvider,
} from 'native-base';

import axios from "axios";

const Example = () => {
  const [showModal, setShowModal] = useState(false);

  const [Nom, setNom] = useState("");
  const [PreNom, setPreNom] = useState("");
  const [Email, setEmail] = useState("");
  const [Telephone, setTelephone] = useState();
  const [Profession, setProfession] = useState("");

  const [Formation, setFormation] = useState("");
  const [Localite, setLocalite] = useState("");
  const [Prix, setPrix] = useState("35000");


  const Enoyer = () => {

    var Datas = {
    Nom : Nom,
    Prenom : PreNom ,
    Email : Email,
    Telephone : Telephone,
    Profession : Profession,
    Formation : Formation,
    Localite : Localite,
    Prix: Prix

    }

    //  alert( JSON.stringify( Datas) )

       
    const TestChamps = VerifSaisie()

    if (TestChamps == 1) {
      // alert("oooooooooooo")
      InsertionData( Datas  )
    }else {
      // alert(" Non ok")
    }


  }   

  const InsertionData =  (MesDonnees) => {

     axios.post( 'http://5.183.8.94:5500/vacances' , MesDonnees )
    // axios.post( 'http://localhost:5500/vacances' , MesDonnees )
        
      
      .then(function (response) {

        let success =  response.data.success
        if ( success === true ){
         // alert ( JSON.stringify( success) )  

          alert( "Votre inscription a été prise en compte \n ACCES Burkina vous dit merci et a très bientôt" )
    
          window.location.href = '/' 

        }else {

          alert (" Vérifiez votre connexion et réessayez")  
        }
      


    
      })
      .catch(function (error) {
        if (error.response) {
    
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
    
    
        }  else if (error.request){
    
          console.log(error.request);
    
        }else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
    
        alert(`Vérifiez votre connexion internet et réessayez \n ou constacter l'administrateur SVP \n ` );
      });
    
    
      }

      const VerifSaisie = () => {

        if (   Nom ==='' ||   PreNom ===''
           ||Telephone  ==='' || Email  ==='' ||  Profession  ==='' ||
             Formation  ==='' ||  Localite ==='' 

      
         ){
      
          // alert('Veuillez remplir tous les champs obligatoires (*) SVP')
          alert('Veuillez remplir tous les champs obligatoires  SVP')
          //setisLoading(0)
          return 0;
      
        } else {
      
          return 1;
        }
      
      
      }
      

      


  const Example = () => {
  } 
    const [isOpen, setIsOpen] = React.useState(false);

    const onClose = () => setIsOpen(false);
  
  return (
    <>
      <Button onPress={() => setShowModal(true)}> <h1 className="titre1"  >
            INSCRIVEZ-VOUS <span className="purple"> VITE </span> 
            </h1></Button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content maxWidth="700px"> 
          <Modal.CloseButton />
          <Modal.Header> Inscription</Modal.Header>
          <Modal.Body className="home-about-body">
       
            <FormControl>
              <FormControl.Label>Nom*</FormControl.Label>
              <Input 
                   value={Nom}   
                   w="100%" 
                   onChangeText={( value ) => {
                    setNom(value)	
                  }}
                   //placeholder="Value Controlled Input" 
                   />
            </FormControl>
           
            <FormControl>
              <FormControl.Label>Pénom(s)*</FormControl.Label>
              <Input 
                   value={PreNom} 
                   w="100%" 
                   onChangeText={( value ) => {
                    setPreNom(value);
                  }}
                   //placeholder="Value Controlled Input" 
                   />
            </FormControl>



            <FormControl>
              <FormControl.Label>Téléphone*</FormControl.Label>
              <Input 
                   value={Telephone} 
                   w="100%" 
                   onChangeText={( value ) => {

                   // const NB = e.target.value

                  if (!isNaN( value )) {
                    setTelephone(value);
                    }else {
                    alert( 'Saisir des chiffres SVP')
                    }
                  
  
                  }}
                   //placeholder="Value Controlled Input" 
                   />
            </FormControl>

            <FormControl mt="3">
              <FormControl.Label>Email</FormControl.Label>
              <Input 
                   value={Email} 
                   w="100%" 
                   onChangeText={( value ) => {
                    setEmail(value);
                  }}
                   //placeholder="Value Controlled Input" 
                   />
            </FormControl>

            <FormControl>
              <FormControl.Label>Profession*</FormControl.Label>
              <Input 
                   value={Profession} 
                   w="100%" 
                   onChangeText={( value ) => {
                    setProfession(value);
                  }}
                   //placeholder="Value Controlled Input" 
                   />
          
            </FormControl>

            <FormControl  isRequired isInvalid>
            <FormControl.Label>Choix de la formation*</FormControl.Label>
        <Select 
                // accessibilityLabel="Choose Service" 
                // placeholder="Choose Service" 
                _selectedItem={{
                //  bg: "teal.600",
                  endIcon: <CheckIcon size={5} />
                }} 
               // selectedValue={service}

               onValueChange={(e) => {
                  //alert(e)	
                  setFormation(e)
                }}


                mt="1"
                >
          <Select.Item label="Initiation à l’infographie (Illustrator, Photoshop et In design)" value="Initiation à l’infographie (Illustrator, Photoshop et In design)" />
          <Select.Item label="Perfectionnement en infographie (Illustrator & Photoshop)" value="Perfectionnement en infographie (Illustrator & Photoshop)" />
          <Select.Item label="Spécialisation (un logiciel au choix)" value="Spécialisation (un logiciel au choix)" />

        </Select>
        {/* <FormControl.ErrorMessage 
            //  leftIcon={<WarningOutlineIcon size="xs" />}
             >
          {"11"}
        </FormControl.ErrorMessage> */}

          </FormControl>

          {/* <FormControl>
              <FormControl.Label>Montant*</FormControl.Label>
              <Input 
                   value={Prix} 
                   w="100%" 
                   //placeholder="Value Controlled Input" 
                   />
          
            </FormControl> */}
            

      <FormControl  isRequired isInvalid>
            <FormControl.Label>Localité*</FormControl.Label>
        <Select 
                // accessibilityLabel="Choose Service" 
                // placeholder="Choose Service" 
                _selectedItem={{
                //  bg: "teal.600",
                  endIcon: <CheckIcon size={5} />
                }} 
               // selectedValue={service}

               onValueChange={(e) => {
                  //alert(e)	
                  setLocalite(e)
                  
                }}


                mt="1"
                >
          <Select.Item label="Ouagadougou" value="Ouagadougou" />
          <Select.Item label="BoboDioulasso" value="BoboDioulasso" />
          <Select.Item label="Koudougou" value="Koudougou" />

        </Select>
        {/* <FormControl.ErrorMessage 
            //  leftIcon={<WarningOutlineIcon size="xs" />}
             >
          {"11"}
        </FormControl.ErrorMessage> */}

      </FormControl>




          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setShowModal(false);
                }}>
                Annuler
              </Button>
              <Button
                onPress={() => {
                  // setShowModal(false);
                  // alert (Nom)
                  Enoyer()
                }}>
                Envoyer
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};


const ExampleB = () => {
  const [placement, setPlacement] = useState(undefined);
  const [open, setOpen] = useState(false);

  const openModal = placement => {
    setOpen(true);
    setPlacement(placement);
  };

  return <>
      <Stack direction={{
      base: "column",
      md: "row"
    }} space={2}>
        <Button onPress={() => openModal("top")}>Top</Button>
        <Button onPress={() => openModal("bottom")}>Bottom</Button>
        <Button onPress={() => openModal("center")}>Center</Button>
        <Button onPress={() => openModal("left")}>Left</Button>
        <Button onPress={() => openModal("right")}>Right</Button>
      </Stack>
      <Modal isOpen={open} onClose={() => setOpen(false)} safeAreaTop={true}>
        <Modal.Content maxWidth="350" {...styles[placement]}>
          <Modal.CloseButton />
          <Modal.Header>Contact Us</Modal.Header>
          <Modal.Body>
            <FormControl>
              <FormControl.Label>Name</FormControl.Label>
              <Input />
            </FormControl>
            <FormControl mt="3">
              <FormControl.Label>Email</FormControl.Label>
              <Input />
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="ghost" colorScheme="blueGray" onPress={() => {
              setOpen(false);
            }}>
                Cancel
              </Button>
              <Button onPress={() => {
              setOpen(false);
            }}>
                Save
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>;
};

  const Projects = () => {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
 
         <Row>
          <Col md={12} className="home-about-description">
          <h1 className="titre1"  >
            INSCRIVEZ-VOUS <span className="purple"> VITE </span> 
            </h1>

            <Box>Hello world</Box>
            <p >
             <b className="purple">Ouagadougou - BoboDioulasso - Koudougou</b> 
            
              <br /> <br />

    
            </p>
          </Col>

        </Row>
        
    
      </Container>
    </Container>
  );
}

const styles = {
  top: {
    marginBottom: "auto",
    marginTop: 0
  },
  bottom: {
    marginBottom: 0,
    marginTop: "auto"
  },
  left: {
    marginLeft: 0,
    marginRight: "auto"
  },
  right: {
    marginLeft: "auto",
    marginRight: 0
  },
  center: {}
};

export default Example;
